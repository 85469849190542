<template>
<Page id="tips-tips-questions" title="Tipsa frågor">
     <QTable></QTable>
</Page>
</template>

<script>
const Page = () => import("@/components/common/Page");
const QTable = () => import("@/components/tips/add/questions/QuestionsTable");

export default {
      name: "TipsQuestions",
      components: {            
            Page,
            QTable
      },
      metaInfo: {
            title: "Tipsa frågor",
            meta: [{
                  name: "description",
                  content: "Här är alla frågor för turneringen",
            }, ],
      },
      mounted() {
            this.$store.dispatch("tipsg/getTipsQuestionsTips");            
      },
};
</script>
